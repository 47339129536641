<template>
  <div :id="`scrollWrapper`" class="overflow-hidden relative">
    <div
      id="scrollContent"
      class="flex flex-col items-center w-full h-screen relative text-ol-purple"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
export default {
  name: 'VerticalTextAnim',

  props: {
    blur: {
      type: String,
      default: '75',
    },
  },

  mounted() {
    let wrapperId = '#scrollWrapper';
    let contentId = 'scrollContent';
    let items = gsap.utils.toArray(wrapperId + ' .knock');
    let end = document.getElementById(contentId).children.length * 600;
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrapperId,
        start: 'bottom bottom',
        end: '+=' + end,
        scrub: 2,
        snap: 1 / (items.length - 1),
        pin: true,
      },
    });
    for (let i = 0; i < items.length; i++) {
      if (i !== 0) {
        tl.from(items[i], { opacity: 0, y: 500 }, '<-=25%');
      }
      tl.addLabel('middle');
      if (i !== items.length - 1) {
        tl.to(items[i], {
          opacity: 0,
          y: -500,
        });
      }
    }
  },
};
</script>
