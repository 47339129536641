<template>
  <div class="order-3">
    <div
      ref="trustbox"
      data-testid="trust-pilot"
      class="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="5419b732fbfb950b10de65e5"
      data-businessunit-id="657080ebfa8c7e8d395f33bd"
      data-style-height="24px"
      data-style-width="280px"
      :data-theme="props.theme"
      data-font-family="Montserrat"
      :data-text-color="textColour"
    />
  </div>
</template>
<script setup>
import { computed, ref, watch } from 'vue';

const trustbox = ref(null);

watch(
  () => trustbox.value,
  () => {
    if (trustbox.value && window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustbox.value);
    }
  }
);

const props = defineProps({
  theme: {
    type: String,
    default: 'dark',
    validator(value) {
      return ['light', 'dark'].includes(value);
    },
  },
});
const textColour = computed(() => {
  if (props.theme === 'dark') {
    return '#FFFEF3';
  }
  return '#000000';
});
</script>
