<template>
  <svg
    width="4"
    height="104"
    viewBox="0 0 4 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2L2 102"
      :stroke="stroke"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  darkMode: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const stroke = computed(() => (props.darkMode ? '#FFFFFF' : '#E3D8BE'));
</script>
